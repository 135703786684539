<template>
  <a-select
    mode="multiple"
    show-search
    :placeholder="placeholder || $t('请选择店铺')"
    style="width: 100%"
    v-model="selectShops"
    :disabled="disabled"
    :showArrow="true"
    :maxTagCount="maxTagCount"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="filterOption"
    @change="changeHandle"
    @focus="getShopList"
  >
    <a-select-option
      v-for="item in shop_list"
      :key="item.shopCode"
      :value="item.shopCode"
      :option="item"
    >
      {{ item.platformLocale }}-{{ item.name }}
    </a-select-option>
  </a-select>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    placeholder: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    allShop: {},
    shopCodeList: {},
    maxTagCount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      selectShops: [],
      dataType: 'string',
      shop_list: [],
    }
  },
  mounted() {
    this.getShopList()
  },
  computed: {
    ...mapGetters(['currentShop']),
  },
  methods: {
    ...mapActions(['getShopInfoList']),
    clear() {
      this.selectShops = []
    },
    getShopList() {
      if (this.shop_list.length) return
      this.getShopInfoList((list) => {
        if (!this.allShop) {
          let partyId = this.$store.getters.partyId
          list = list.filter((item) => item.partyId == partyId)
        }
        this.shop_list = list
      })
    },
    changeHandle(value, option) {
      let selectShopList = option.map((item) => item.data.attrs.option)
      let shopCodes = option.map((item) => item.data.attrs.option.shopCode)
      this.$emit('update:shopCodeList', shopCodes)
      this.$emit('change', selectShopList)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>
