import heartCheck from './heartCheck'

export default class OSocket {
  constructor() {
    this.lockReconnect = false //避免ws重复连接
    this.ws = null // 判断当前浏览器是否支持WebSocket
    this.needReconnect = true
  }
  //连接
  createWebSocket(url, callback, callback2) {
    try {
      if ('WebSocket' in window) {
        this.ws = new WebSocket(url)
        this.initEventHandle(url, callback, callback2)
      } else {
        alert(
          '您的浏览器不支持websocket协议,建议使用新版谷歌、火狐等浏览器，请勿使用IE10以下浏览器，360浏览器请使用极速模式，不要使用兼容模式！'
        )
      }
    } catch (e) {
      this.reconnect(url, callback)
    }
  }
  //重连
  reconnect(url, callback) {
    console.log('重连', this.needReconnect, this.lockReconnect)
    if (!this.needReconnect) return
    if (this.lockReconnect) return
    this.lockReconnect = true
    setTimeout(() => {
      //没连接上会一直重连，设置延迟避免请求过多
      this.lockReconnect = false
      this.createWebSocket(url, callback)
    }, 2000)
  }
  initEventHandle(url, callback, callback2) {
    this.ws.onopen = () => {
      console.log('llws连接成功!' + new Date().toUTCString())
      callback2 && callback2()
      heartCheck.reset().start(this.ws) //心跳检测重置
    }
    this.ws.onclose = () => {
      console.log('llws连接关闭!' + new Date().toUTCString())
      // this.reconnect(url, callback)
    }
    this.ws.onerror = () => {
      console.log('llws连接错误!')
      this.reconnect(url, callback)
    }
    this.ws.onmessage = (event) => {
      //如果获取到消息，心跳检测重置
      heartCheck.reset().start(this.ws) //拿到任何消息都说明当前连接是正常的
      let data = event.data
      try {
        data = JSON.parse(data)
      } catch (e) {
        // console.log(e);
      }
      if (data === 'ping') {
        return
      }
      console.log('llws收到消息啦:', data)
      callback && callback(data)
    }
  }
  send(data, callback) {
    this.ws.send(data)
    callback && callback()
  }
  close() {
    this.ws.close()
  }
}
